import React, { useState, useEffect } from 'react';
import './Course.css';

const modules = [
  { id: 1, title: 'Покажи, что у нас тут? ', content: 'Когда ты заходишь в какую-то папку, тебе хочется увидеть, что в ней лежит. Команда ls как раз для этого' },
  { id: 2, title: 'Перемещаемся по миру Linux', content: 'Когда тебе нужно перейти в другую папку, ты используешь команду cd (от англ. change directory — изменить каталог).' },
  { id: 3, title: 'Где я?', content: 'Если ты потерялся в дебрях системы и не понимаешь, где находишься, команда pwd (от англ. print working directory — вывести текущий каталог) подскажет тебе' },
  { id: 4, title: 'Строим новое "здание" ', content: 'Если тебе нужно создать новую папку, команда mkdir (от англ. make directory — создать каталог) поможет' },
  { id: 5, title: 'Удаляем ненужное', content: 'Когда пора избавиться от ненужных файлов, команда rm (от англ. remove — удалить) поможет' },
];

const Course = () => {
  const [command, setCommand] = useState('');
  const [output, setOutput] = useState('');
  const [prepareMessage, setPrepareMessage] = useState('');
  const [containerId, setContainerId] = useState('');
  const [selectedModule, setSelectedModule] = useState(null);
  const [completedModules, setCompletedModules] = useState([]);

  useEffect(() => {
    const storedCompletedModules = JSON.parse(localStorage.getItem('completedModules')) || [];
    setCompletedModules(storedCompletedModules);
  }, []);

  const handleCommandChange = (e) => {
    setCommand(e.target.value);
  };

  const handleCommandSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('jwtToken'); // Получаем JWT токен из локального хранилища

      const response = await fetch('http://localhost:6689/manager/execute_command_in_container', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ username: token, command }), // Передаем токен как username
      });

      if (response.ok) {
        const data = await response.json();
        setOutput(data.output.split('\n').map(line => `lious#: ${line}`).join('\n'));
        if (selectedModule && !completedModules.includes(selectedModule.id)) {
          const updatedCompletedModules = [...completedModules, selectedModule.id];
          setCompletedModules(updatedCompletedModules);
          localStorage.setItem('completedModules', JSON.stringify(updatedCompletedModules));
        }
      } else {
        const errorData = await response.json();
        setOutput('Error executing command: ' + errorData.message);
      }
    } catch (error) {
      setOutput('Error executing command');
    }
  };

  const handlePrepareContainer = async () => {
    try {
      const token = localStorage.getItem('jwtToken'); // Получаем JWT токен из локального хранилища

      const response = await fetch('http://localhost:6689/manager/prepare_container', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ username: token }), // Передаем токен как username
      });

      if (response.ok) {
        const data = await response.json();
        setContainerId(data.containerId || ''); // Логируем ID контейнера
        setPrepareMessage('Ваш Линукс готов');
      } else {
        const errorData = await response.json();
        setPrepareMessage('Не удалось запустить Линукс ' + errorData.message);
      }
    } catch (error) {
      setPrepareMessage('Error preparing container');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleCommandSubmit(e);
    }
  };

  const handleModuleClick = (module) => {
    setSelectedModule(module);
  };

  const isCourseCompleted = modules.length === completedModules.length;

  return (
    <div className="course-container">
      <div className="top-left">
        <button onClick={() => window.location.href = '/'}>На главную</button>
        <button onClick={handlePrepareContainer}>Запустить Linux</button>
      </div>
      <div className="content">
        <div className="modules">
          <h2>Тема 1: Основные команды терминала</h2>
          <ul>
            {modules.map((module) => (
              <li key={module.id} onClick={() => handleModuleClick(module)}>
                {module.title}
                {completedModules.includes(module.id) && <span className="checkmark">✔</span>}
              </li>
            ))}
          </ul>
          {selectedModule && (
            <div className="module-content">
              <h3>{selectedModule.title}</h3>
              <p>{selectedModule.content}</p>
            </div>
          )}
        </div>
        <div className="command-section">
          <div className="prepare-message">
            <pre>{prepareMessage}</pre>
          </div>
          {containerId && (
            <div className="container-id">
              <p>Container ID: {containerId}</p>
            </div>
          )}
          <form onSubmit={handleCommandSubmit} className="command-form">
            <input
              type="text"
              value={command}
              onChange={handleCommandChange}
              onKeyPress={handleKeyPress}
              placeholder="Введите команду"
              className="command-input"
            />
            <button type="submit" className="command-submit">Отправить</button>
          </form>
          <div className="command-output">
            <pre>{output}</pre>
          </div>
          {isCourseCompleted && (
            <div className="course-completion">
              <h3>Поздравляем! Вы завершили курс.</h3>
              <button onClick={() => window.location.href = '/'}>На главную</button>
              <button onClick={() => window.location.reload()}>Остаться на странице</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Course;