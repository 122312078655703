import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Course from './Course';
import logo from './logo.svg'; // Add the logo import

// Modal Component
const Modal = ({ children, isOpen, onClose }) => {
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>×</button>
        {children}
      </div>
    </div>
  );
};

// LoginForm Component
const LoginForm = ({ formData, handleChange, handleSubmit, errors }) => (
  <form id="login" onSubmit={handleSubmit} className="form-container">
    <label>
      Логин
      <input
        type="text"
        name="username"
        placeholder="Ваш логин"
        required
        value={formData.username}
        onChange={handleChange}
      />
      {errors.username && <p className="error">{errors.username}</p>}
    </label>
    <label>
      Пароль
      <input
        type="password"
        name="password"
        placeholder="Ваш пароль"
        required
        value={formData.password}
        onChange={handleChange}
      />
      {errors.password && <p className="error">{errors.password}</p>}
    </label>
    <button type="submit">Войти</button>
  </form>
);

// RegisterForm Component
const RegisterForm = ({ registerData, handleChange, handleSubmit, errors }) => (
  <form id="register" onSubmit={handleSubmit} className="form-container">
    <label>
      Email
      <input
        type="email"
        name="email"
        placeholder="Ваш email"
        required
        value={registerData.email}
        onChange={handleChange}
      />
      {errors.email && <p className="error">{errors.email}</p>}
    </label>
    <label>
      Логин
      <input
        type="text"
        name="username"
        placeholder="Ваш логин"
        required
        value={registerData.username}
        onChange={handleChange}
      />
      {errors.username && <p className="error">{errors.username}</p>}
    </label>
    <label>
      Пароль
      <input
        type="password"
        name="password"
        placeholder="Ваш пароль"
        required
        value={registerData.password}
        onChange={handleChange}
      />
      {errors.password && <p className="error">{errors.password}</p>}
    </label>
    <button type="submit">Зарегистрироваться</button>
  </form>
);

const App = () => {
  // State variables
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [registerData, setRegisterData] = useState({ email: '', username: '', password: '' });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  // Check for token in localStorage on app initialization
  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    const storedUsername = localStorage.getItem('username');
    if (token && storedUsername) {
      setIsLoggedIn(true);
      setUsername(storedUsername);
    }
  }, []);

  // Handlers for form changes
  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({ ...registerData, [name]: value });
  };

  // Validation function
  const validate = (data) => {
    const errors = {};
    if (!data.username || data.username.length < 3) {
      errors.username = 'Логин должен содержать не менее 3 символов';
    }
    if (!data.password || data.password.length < 6) {
      errors.password = 'Пароль должен содержать не менее 6 символов';
    }
    if (data.email && !/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Некорректный email';
    }
    return errors;
  };

  // Handlers for form submissions
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    try {
      const response = await fetch('http://localhost:5340/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.result) {
        setIsLoggedIn(true);
        setUsername(formData.username);
        localStorage.setItem('jwtToken', data.token); // Store token in localStorage
        localStorage.setItem('username', formData.username); // Store username in localStorage
        setShowLoginModal(false); // Close the login modal
      }
      console.log('Login Response:', data);
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const handleRegisterSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate(registerData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    try {
      const response = await fetch('http://localhost:5340/auth/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(registerData),
      });
      const data = await response.json();
      console.log('Register Response:', data);
      if (data.result) {
        setIsLoggedIn(true);
        setUsername(registerData.username);
        localStorage.setItem('jwtToken', data.token); // Store token in localStorage
        localStorage.setItem('username', registerData.username); // Store username in localStorage
        setShowRegisterModal(false); // Close the register modal
        setSuccessMessage(`Вы успешно зарегистрировались, ${registerData.username}!`);
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000); // Hide the success message after 3 seconds
      }
    } catch (error) {
      console.error('Error during registration:', error);
    }
  };

  // Handler for logout
  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    setFormData({ username: '', password: '' });
    localStorage.removeItem('jwtToken'); // Remove token from localStorage
    localStorage.removeItem('username'); // Remove username from localStorage
  };

  const handleStartFree = () => {
    if (isLoggedIn) {
      navigate('/course');
    } else {
      setShowLoginModal(true); // Show the login modal if not authenticated
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src={logo} className="App-logo" alt="logo" /> {/* Add the logo */}
          <h1>Lious</h1>
        </div>
        <nav>
          {isLoggedIn ? (
            <div>
              <p className={`message ${showSuccessMessage ? 'fade-in' : ''}`}>
                {showSuccessMessage ? successMessage : `Привет, ${username}!`}
              </p>
              <button onClick={handleLogout}>Выйти</button>
            </div>
          ) : (
            <>
              <button onClick={() => setShowLoginModal(true)}>
                Войти
              </button>
              <button onClick={() => setShowRegisterModal(true)}>
                Зарегистрироваться
              </button>
            </>
          )}
        </nav>
      </header>
      <main className="main-container">
        <Routes>
          <Route path="/course" element={<Course />} />
          <Route path="/" element={
            <>
              <section className="section-container">
                <h3>Linux для начинающих</h3>
                <div className="content-box">
                  <section className="module">
                    <h4>Основы командной строки</h4>
                    <p>Изучите основные команды и научитесь эффективно работать в командной строке.</p>
                  </section>
                  <section className="module">
                    <h4>Работа с файлами и директориями</h4>
                    <p>Научитесь создавать, редактировать и управлять файлами и директориями.</p>
                  </section>
                  <section className="module">
                    <h4>Права доступа и управление пользователями</h4>
                    <p>Узнайте, как управлять правами доступа и пользователями в системе Linux.</p>
                  </section>
                  <section className="module">
                    <h4>Установка и обновление программ</h4>
                    <p>Освойте методы установки и обновления программного обеспечения в Linux.</p>
                  </section>
                  <section className="module">
                    <h4>Основы работы с сетью</h4>
                    <p>Изучите основные сетевые команды и научитесь настраивать сеть в Linux.</p>
                  </section>
                </div>
                <button onClick={handleStartFree}>Начать бесплатно учиться</button>
              </section>
            </>
          } />
        </Routes>
      </main>
      <Modal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)}>
        <LoginForm
          formData={formData}
          handleChange={handleLoginChange}
          handleSubmit={handleLoginSubmit}
          errors={errors}
        />
      </Modal>
      <Modal isOpen={showRegisterModal} onClose={() => setShowRegisterModal(false)}>
        <RegisterForm
          registerData={registerData}
          handleChange={handleRegisterChange}
          handleSubmit={handleRegisterSubmit}
          errors={errors}
        />
      </Modal>
    </div>
  );
};

export default App;
